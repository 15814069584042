import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { Link } from "react-router-dom";
import Client from "./Clients";
const PortfolioList = [
  {
    image: "image-6",
    category: "",
    title: "Graymalin",
    image: 'graymalin',
    backgroundcolor: '#FFFFFF'
  },
  {
    image: "image-6",
    category: "",
    title: "Wellsfargo",
    image: 'wellsFargo',
    backgroundcolor: '#D71E28'

  },
  {
    image: "image-6",
    category: "",
    title: "Capitalone",
    image: 'capitalOne',
    backgroundcolor: '#F4F4F4'

  },
  {
    image: "image-6",
    category: "",
    title: "Southwest",
    image: 'southwest',
    backgroundcolor: '#467DCF'

  },
  {
    image: "image-6",
    category: "",
    title: "Yalehealth",
    image: 'YaleHealth',
    backgroundcolor: '#E5EFEC'

  },
  {
    image: "image-6",
    category: "",
    title: "Drinkag1",
    image: 'AG1',
    backgroundcolor: '#EDEBE4'

  },
  {
    image: "image-7",
    category: "",
    title: "whatboard",
    image: 'whatboard',
    backgroundcolor: '#253276'

  },

  {
    image: "image-8",
    category: "",
    title: "stcroixrods",
    image: 'stcroixrods',
    backgroundcolor: '#D5B854'

  },

  {
    image: "image-9",
    category: "",
    title: "copart",
    image: 'copart',
    backgroundcolor: '#09329D'

  },

  {
    image: "image-10",
    category: "",
    title: "scoop",
    image: 'scoop',
    backgroundcolor: '#F7F7FA'

  },

  {
    image: "image-11",
    category: "",
    title: "lalignenyc",
    image: 'lalignenyc',
    backgroundcolor: '#FFFFFF'
  },

  {
    image: "image-12",
    category: "",
    title: "autoninjas",
    image: 'AutoNinjas',
    backgroundcolor: '#3775DF'
  },
  ,

  {
    image: "image-13",
    category: "",
    title: "American whirlpool",
    image: 'americanwhirlpool',
    backgroundcolor: '#030303'
  }
];

class Portfolio extends Component {
  render() {
  {/* let title = "Our Clients"
     description =
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration."; */}
    return (
      <React.Fragment>
      
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
             {/* <div className="col-lg-6">
                <div className="section-title">
                  
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
    <div className="portfolio-slick-activation mt--70 mt_sm--40"> */}
          <Client/>

            {/* <Slider {...portfolioSlick2}>
              {PortfolioList.map((value, index) => (
                <div className="portfolio" key={index}>
                  
                  <div className="thumbnail-inner" >
                    <div className={`thumbnail ${value.image}`}   style={{backgroundColor: value.backgroundcolor}}>  </div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , bottom: '150px'}}>
                    <div className="inner" >
                    <img src={`/assets/images/portfolio/icon/${value.image}.png`} alt="Logo Images" />

                      <p>{value.category}</p>

                      <div className="portfolio-button">
                        <a className="rn-btn" href="/portfolio-details">
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                  <Link className="link-overlay" to="/portfolio-details"></Link>
                </div>
              ))}
            </Slider> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
