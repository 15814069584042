import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | Taatgen Development </title>
                    <meta name="description" content="Taatgen Development is a world-class digital agency specializing in custom mobile-responsive websites, SEO, mobile app development, e-commerce solutions, and data analytics. We offer reliable, consistent, and diverse software solutions developed by expert engineers using the latest market technologies and frameworks. As a Top-Rated Plus agency, we prioritize client needs through thorough communication and offer 24/7 global support. Contact us today to solve your digital problems and build a website that reflects your brand and meets your corporate needs." />
                    <meta name="keywords" content="Taatgen Development, digital agency, custom websites, mobile-responsive websites, SEO, mobile app development, e-commerce solutions, data analytics, software solutions, web development, corporate websites, WordPress development, custom website design, web technologies, Upwork, expert engineers, global support, digital problems, brand development, Full Stack Development, ReactJS, AngularJS, Node.js, front-end development, back-end development, JavaScript, web applications, software engineering, technology frameworks, responsive design, UI/UX design" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
