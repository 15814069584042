import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Mike is an absolute joy to work with. He is not only
                      highly skilled, but also a very personable and is able to
                      easily build rapport with his clients. His experience in
                      the industry shines through in his work, and his deep
                      understanding of different technologies and stacks makes
                      him an asset to any team. Once he takes on a project, he
                      invests himself fully in ensuring that it meets the
                      highest standards of quality. He consistently goes above
                      and beyond what is expected of him, and his attention to
                      detail is unparalleled
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Adeeb A. </span> - React Engineer
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      I recently had the privilege of working with Mike on a
                      contracted project, where we developed the front end for a
                      suite of media management apps. Mike's outstanding
                      technical skills and keen eye for detail were evident from
                      the start. Quickly adapting to various software packages,
                      Mike demonstrated strong problem-solving skills, enabling
                      swift progress on tasks. His collaborative spirit and
                      excellent communication made working with him a pleasure,
                      as he eagerly shared ideas for potential enhancements and
                      improvements. I wholeheartedly recommend Mike as a
                      highly-skilled React developer and valuable team player.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Shawn K. </span> - React Engineer
                    </h6>
                  </div>
                </div>
              </TabPanel>
              {/* <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Jannat Tumpa The standard chunk of Lorem Ipsum used since
                      the 1500s is reproduced below for those interested.
                      Sections Bonorum et Malorum original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Jannat Tumpa </span> - COO, AMERIMAR ENTERPRISES,
                      INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Standard chunk of Lorem Ipsum used since the 1500s is
                      reproduced below for those interested. Sections Bonorum et
                      Malorum original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Johns Due </span> - COO, AMERIMAR ENTERPRISES, INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      John Doe The standard chunk of Lorem Ipsum used since the
                      1500s is reproduced below for those interested. Sections
                      Bonorum et Malorum original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>John Doe </span> - COO, AMERIMAR ENTERPRISES, INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Chunk of Lorem Ipsum used since the 1500s is reproduced
                      below for those interested. Sections Bonorum et Malorum
                      original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Amar Orthi </span> - COO, AMERIMAR ENTERPRISES, INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      The standard chunk of Lorem Ipsum used since the 1500s is
                      reproduced below for those interested. Sections Bonorum et
                      Malorum original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Fatima Ma </span> - COO, AMERIMAR ENTERPRISES, INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Lorem Ipsum used since the 1500s is reproduced below for
                      those interested. Sections Bonorum et Malorum original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES,
                      INC.
                    </h6>
                  </div>
                </div>
              </TabPanel> */}

              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-1.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-2.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                {/* <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-3.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-4.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-5.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-6.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-7.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-8.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab> */}
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
