import React , {useState} from 'react';
import axios from 'axios';

const Result = () => {
    return (
        <p className="success-message">Your message has been successfully sent. We will contact you soon.</p>
    )
}
function ContactForm({props}) {
    const [ result,showresult ] = useState(false);
    const [contactFormData, setContactFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        budget: 'less_than_1000',
        message: ''
    })
    const sendEmail = (e) => {
        e.preventDefault();
        const headers = {
            'x-api-key': 'r6nO55N0LQzXCZziaTjk54J6tIwr7in2BpeWqrLf',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*"
        };
        
          axios.post('https://vdwusdv4l5.execute-api.us-east-2.amazonaws.com/default/sendContactEmailV2', {
                senderName: contactFormData.name,
                senderEmail: contactFormData.email,
                subject: contactFormData.subject,
                message: contactFormData.message,
                phoneNumber: contactFormData.phoneNumber,
                budget: contactFormData.budget
          }, { headers: headers })
          .then(function (response) {
            // handle success
            console.log(response);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
        

        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);


    const handleContactFormInputChange = () => {
        // eslint-disable-next-line no-restricted-globals
        const { name, value } = event.target;
    
        setContactFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input 
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    required
                    onChange={handleContactFormInputChange}
                />
            </div>

            <div className="rn-form-group">
                <input 
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                    onChange={handleContactFormInputChange}
                />
            </div>

            <div className="rn-form-group">
                <input 
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    required
                    onChange={handleContactFormInputChange}
                />
            </div>

            <div className="rn-form-group">
                <input 
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    onChange={handleContactFormInputChange}
                />
            </div>

            <div className="rn-form-group margin" >
            <select className="sel" name="budget" required onChange={handleContactFormInputChange}>
                <option value="" disabled selected hidden>Budget</option>
                <option value="less_than_1000">Less than $1,000</option>
                <option value="upto_5000">$1,000-$5,000</option>
                <option value="upto_15000">$5,000-$15,000</option>
                <option value="more_than_15000">More than $15,000</option>
            </select>
            </div>
                        
            <div className="rn-form-group">
                <textarea 
                    name="message"
                    placeholder="Your Message"
                    required
                    onChange={handleContactFormInputChange}
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
            </div> 

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
