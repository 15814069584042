import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
//import Reviews from "../component/Reviews";
//import SliderReviews from '../component/SliderReviews'
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
//import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
//import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import Brand from "../elements/Brand";
import { MdOutlineFindInPage, MdOutlineDesignServices, MdDeveloperMode, MdSupportAgent } from "react-icons/md";

class MainDemo extends Component {
  render() {
    const PostList = BlogContent.slice(0, 3);
    return (
      <Fragment>
        <Helmet pageTitle="Home" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area about-position-top pt--80 pb--120">
          <About />
        </div>
        {/* End About Area */}

        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>

        {/* Start Brand Area  */}
        <div className="rn-brand-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12  pb--60">
                <div className="section-title text-center">
                  <h3 className="centered-heading">Our Favorite Code-Stack</h3>
                </div>
              </div>
              <div className="col-lg-12">
                <Brand branstyle="branstyle--2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--200 bg_color--1">
          <div className="portfolio-sacousel-inner mb--80">
            <Portfolio />
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--140 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="centered-heading">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div> */}
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        {/* <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="section-title text-left">
                  <h2>Latest News</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                  <a className="btn-transparent rn-btn-dark" href="/blog">
                    <span className="text">View All News</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* End Blog Area */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>

        {/*<div style={{display: 'flex', justifyContent:'center', marginTop: '50px'}}>
        <h3 className="title">Here’s What Our Customers Have Said</h3>
          </div>
        <div className="review-container" style={{marginBottom: '70px'}}>
                <Reviews
                    description="Great. Would hire again!"
                    rating={4}
                    userName="John Doe"
                    profession="Software Engineer"
                />
                <Reviews
                    description="Mike did a great job."
                    rating={4}
                    userName="John "
                    profession="Officer"
                />
                 <Reviews
                    description="Mike is a great resources. Hardworking, extra-effort kinda guy."
                    rating={4}
                    userName="salman"
                    profession="Software Engineer"
                />
                 <Reviews
                    description="Mike is a friendly,intelligent, and communicative developer."
                    rating={4}
                    userName="salman"
                    profession="Software Engineer"
                />
                 <Reviews
                    description="Wow. what a pro. Incredibly responsive, flexible and knowledgeable."
                    rating={4}
                    userName="salman"
                    profession="Software Engineer"
                />
                 <Reviews
                    description="Mike helped us integrate a tax calculation system in our web application."
                    rating={4}
                    userName="salman"
                    profession="Software Engineer"
                />
      </div>*/}
        {/* End Back To Top */}
        {/* 
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
      <div style={{ width: '200px', margin: '0 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid black' }}>
        <img src={`/assets/images/process/p1.png`} alt="Image 1" style={{ width: '30%', height: '30%' }} />
        <p>Discovery</p>
      </div>
      <div style={{ width: '200px', margin: '0 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid black' }}>
        <img src={`/assets/images/process/p2.png`} alt="Image 2" style={{ width: '30%', height: '30%' }} />
        <p>Design</p>
      </div>
      <div style={{ width: '200px', margin: '0 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid black' }}>
        <img src={`/assets/images/process/p3.png`} alt="Image 3" style={{ width: '30%', height: '30%' }} />
        <p>Development</p>
      </div>
      <div style={{ width: '200px', margin: '0 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid black' }}>
        <img src={`/assets/images/process/p4.png`} alt="Image 4" style={{ width: '30%', height: '30%' }} />
        <p>Support</p>
      </div>
    </div> */}

        <div style={{ backgroundColor: '#f8f9fc', padding: 50 }}>
          <div className="section-title text-center" >
            <h3 className="centered-heading" style={{ marginTop: 20, marginBottom: 20 }}>Process</h3>
          </div>
          <div className="row"  >
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdOutlineFindInPage size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Discovery</p>
              </div>

            </div>
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdOutlineDesignServices size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Design</p>
              </div>

            </div>
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdDeveloperMode size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Development</p>
              </div>

            </div>
            <div
              className=" col-lg-3 col-md-3 col-sm-6 col-12"

            >
              <div className="process-card">
                <MdSupportAgent size={'42px'} color="#0cc7ff" />
                <p style={{ textAlign: 'center', marginTop: '10px' }}>Support</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default MainDemo;
