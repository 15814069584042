import React, { Component } from "react";

const ServiceList = [
  {
    icon: "01",
    title: "World-class websites using modern design practices. Taatgen Development will design and build a custom mobile-responsive website geared to achieve your specific goals.",
    description: "We Are A Top-Rated Plus Agency!",
  },
  // {
  //   icon: "02",
  //   title: "Industry-Leading Digital Solutions",
  //   description: "We Ensure Technological Excellence.",
  // },
  // {
  //   icon: "03",
  //   title: "Superior Customer Experience",
  //   description: "Highly Recommended By Our Satisfied Clients.",
  // },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={i}>
              <div className="service service__style--1">
                {/* <div className="icon">
                  <img
                    src={`/assets/images/icons/icon-${val.icon}.png`}
                    alt="Digital Agency"
                  />
                </div> */}
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  {/* <p>{val.description}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
