import React from 'react';

const Reviews = ({ description, rating, userName, profession }) => {
    const renderStars = () => {
        const starIcons = [];
        for (let i = 0; i < rating; i++) {
            starIcons.push(<span key={i} style={{color:'yellow', fontSize:'24px'}}>&#9733;</span>); // Unicode for star
        }
        return starIcons;
    };

    return (
        <div className="review">
            <p className="description">{description}</p>
            <div className="rating">
                {renderStars()}
            </div>
            <p className="user-info">{userName}, {profession}</p>
        </div>
    );
};

export default Reviews;
