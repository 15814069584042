import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
//import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Reviews from "../component/Reviews";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiGrid } from "react-icons/fi";

import { FcCollaboration } from "react-icons/fc";
import { MdHighQuality } from "react-icons/md";



const ServiceList = [
  {
      icon: <FcCollaboration />,
      title: 'Collaboration is Key:',
      description: <p>
      <b>We're not just a team:</b> We're your partners in digital innovation. Our Git prowess ensures seamless collaboration and version control, keeping us all on the same page – literally.
      </p>
  },

  {
    icon: <MdHighQuality />,
    title: 'Our Quality Promise:',
    description:  <p>
    <b>Beyond Bug-Fixing: </b>We don't just test; we future-proof. Using SinonJS, MochaJS, Jest, and Cypress, we ensure your product remains robust under any circumstance. As E2E development experts, we cover every stage from initial concept to the final click, ensuring every pixel is perfect with tools like Protractor, WebDriver, and Selenium.
    </p>
},
  {
      icon: <FiGrid  />,
      title: 'Why We Stand Out:',
      description:  <p>
      <b> Deep Tech Know-How:</b> Our arsenal goes beyond HTML, CSS, and JavaScript. We're the wizards of frameworks like Bootstrap and Zurb Foundation, and we conjure up digital magic with ReactJS, AngularJS, NextJS, and Gatsby. MERN Stack Maestros: Our dedication to ReactJS goes beyond the basics of coding. It's about designing web interfaces that mirror the vibrancy and responsiveness of your audience. Backend Brilliance: MongoDB, REST APIs – you name it, we’ve mastered it. We stitch together front and backend technologies into a seamless digital tapestry.
      </p>
  },
  {
      icon: <FiUsers />,
      title: 'Praise from Our Clients:',
      description: <p>
      <b>Our clients are at the heart of everything we do,</b> and their satisfaction speaks volumes. They have consistently lauded our team for not only meeting but exceeding expectations. From our proactive communication to our problem-solving acumen, clients appreciate the unique blend of technical expertise and customer-centric approach we bring to each project. Their testimonials are a testament to our commitment to excellence and innovation in the realm of web development. At Taatgen Development LLC, we’re more than developers; we're architects of the digital age. If you're looking for a team that values innovation, craftsmanship, and cutting-edge solutions, let's talk. We're ready to elevate your web applications to unparalleled heights. Let’s create something extraordinary together.
      </p>
  }
]
class About extends Component {

//    reviewData  = [
//     { description: "1 Great product!", rating: 5, userName: "John Doe", profession: "Software Engineer" },
//     { description: " 2Excellent service!", rating: 4, userName: "Jane Smith", profession: "Marketing Manager" },
//     { description: "3 Excellent service!", rating: 4, userName: "Jane Smith", profession: "Marketing Manager" },
//     { description: "4 Excellent service!", rating: 4, userName: "Jane Smith", profession: "Marketing Manager" },
//     { description: "5 Excellent service!", rating: 4, userName: "Jane Smith", profession: "Marketing Manager" },

//     // Add more review objects as needed
// ];
  render() {
    let title = "About Taatgen Development",
      description = `Taatgen Development LLC founded in 2023 by our founder Mr. Mike Taatgen who is a senior software engineer with expertise in diverse sectors of software application development. Mr. Mike is assessed as an Expert-Vetted Talent - the top 1% — pre-screened by Upwork Talent Managers and experts in their field. We are based in Irving, United States and our team includes industry experts in providing complete software solution including Front-End, Back-End, Mobile Application Development & Software Testing.
        Mr. Mike Taatgen along with iron-bond working relationship with his colleague Mr. Murtaza Usman, a senior front-end software engineer, developed an environment of satisfying diverse client requirements with utmost technological excellence.`;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About TDL"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 ">
                <div className="col-lg-3" >
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-12.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>

                <div className="col-lg-9">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>

                    </div>
                    </div>

                    <div className="col-lg-12 col-12 mt_md--50" style={{marginTop: '70px'}}>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className="row mt--30">
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Collaboration is Key:</h3>
                          <p>
                          <b>We're not just a team:</b> We're your partners in digital innovation. Our Git prowess ensures seamless collaboration and version control, keeping us all on the same page – literally.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Why We Stand Out:</h3>
                          <p>
                          <b> Deep Tech Know-How:</b> Our arsenal goes beyond HTML, CSS, and JavaScript. We're the wizards of frameworks like Bootstrap and Zurb Foundation, and we conjure up digital magic with ReactJS, AngularJS, NextJS, and Gatsby. MERN Stack Maestros: Our dedication to ReactJS goes beyond the basics of coding. It's about designing web interfaces that mirror the vibrancy and responsiveness of your audience. Backend Brilliance: MongoDB, REST APIs – you name it, we’ve mastered it. We stitch together front and backend technologies into a seamless digital tapestry.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Praise from Our Clients:</h3>
                          <p>
                          <b>Our clients are at the heart of everything we do,</b> and their satisfaction speaks volumes. They have consistently lauded our team for not only meeting but exceeding expectations. From our proactive communication to our problem-solving acumen, clients appreciate the unique blend of technical expertise and customer-centric approach we bring to each project. Their testimonials are a testament to our commitment to excellence and innovation in the realm of web development. At Taatgen Development LLC, we’re more than developers; we're architects of the digital age. If you're looking for a team that values innovation, craftsmanship, and cutting-edge solutions, let's talk. We're ready to elevate your web applications to unparalleled heights. Let’s create something extraordinary together.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title"> Our Quality Promise: </h3>
                          <p>
                          <b>Beyond Bug-Fixing: </b>We don't just test; we future-proof. Using SinonJS, MochaJS, and Jest, we make sure your product stands robust under any circumstance. E2E Development Guru: From initial concept to final click, we ensure every pixel is perfect with tools like Protractor, WebDriver, and Selenium.
                          </p>
                        </div>
                      </div>
                      </div> */}

                {/* <div className="col-lg-9">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Collaboration is Key</h3>
                          <p>
                            We're your partners in digital innovation. Our Git
                            prowess ensures seamless collaboration and version
                            control, keeping us all on the same page –
                            literally.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Why We Stand Out</h3>
                          <p>
                            Deep Tech Know-How: Our arsenal goes beyond HTML,
                            CSS, and JavaScript.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Praise from Our Clients</h3>
                          <p>
                            Our clients are at the heart of everything we do,
                            and their satisfaction speaks volumes.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Our Quality Promise: </h3>
                          <p>
                            Beyond Bug-Fixing: We don't just test; we
                            future-proof. Using SinonJS, MochaJS, and Jest, we
                            make sure your product stands robust under any
                            circumstance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                  <div className="section-title text-center" >
                    <h3 className="centered-heading" >Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Finding Us Area  */}
        {/* <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h4 className="theme-gradient">Find Your Work Now</h4>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that.
                </p>
                <a className="rn-btn btn-white" href="/about">
                  Get Started
                </a>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img
                  src="/assets/images/about/finding-us-01.png"
                  alt="Finding Images"
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Finding Us Area  */}

        {/* Start Team Area  */}
       {/* <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Skilled Team</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">*/}
              {/* Start Single Team  */}
             {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-01.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Mr. Mike Taatgen</h4>
                    <p className="designation">Founder & CEO</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>*/}
              {/* End Single Team  */}

              {/* Start Single Team  */}
              {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-06.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Mr. Murtaza Usman</h4>
                    <p className="designation">CTO</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* End Single Team  */}

              {/* Start Single Team  */}
             {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-03.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Mr. Sabbir Al Fahad</h4>
                    <p className="designation">Project Manager</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>*/}
              {/* End Single Team  */}

              {/* Start Single Team  */}
              {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-07.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Mr. Muhammad Mustafa</h4>
                    <p className="designation">I.T. Support Manager</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>}
                </div>
              </div>*/}
              {/* End Single Team  */}

              {/* Start Single Team  */}
            {/*  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-10.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Mr. Hamza</h4>
                    <p className="designation">VUE JS Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>*/}
              {/* End Single Team  */}

              {/* Start Single Team  */}
              {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-11.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Mr. Uzair</h4>
                    <p className="designation">React JS Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* End Single Team  */}
           {/* </div>
          </div>
        </div>*/}
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        {/* <div style={{display: 'flex', justifyContent:'center'}}>
        <h3 className="title">Here’s What Our Customers Have Said</h3>
          </div>
        <div className="review-container">
                <Reviews
                    description="Great. Would hire again!"
                    rating={4}
                    userName="John Doe"
                    profession="Software Engineer"
                />
                <Reviews
                    description="Mike did a great job."
                    rating={4}
                    userName="John "
                    profession="Officer"
                />
                 <Reviews
                    description="Mike is a great resources. Hardworking, extra-effort kinda guy."
                    rating={4}
                    userName="salman"
                    profession="Software Engineer"
                />
                 <Reviews
                    description="1Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    rating={4}
                    userName="John Doe"
                    profession="Software Engineer"
                />
                 <Reviews
                    description="2Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    rating={4}
                    userName="John Doe"
                    profession="Software Engineer"
                />
                 <Reviews
                    description="3Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    rating={4}
                    userName="John Doe"
                    profession="Software Engineer"
                />
                </div> */}

       
  <div style={{marginTop: '50px'}}>
        <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default About;


  // "scripts": {
  //   "start": "node scripts/start.js",
  //   "build": "node scripts/build.js",
  //   "test": "node scripts/test.js"
  // },