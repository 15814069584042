import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Brand = ({ branstyle }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className={`brand-list ${branstyle} slider-container`}>
      <div className="carousel-item">
        <img src="/assets/images/brand/reactjs.jpeg" className="carousel-image carousel-image-react" alt="React Logo" />
      </div>
      {/*<div className="carousel-item">
        <img src="/assets/images/brand/javascript-logo.png" alt="JavaScript Logo" />
      </div>*/}
      <div className="carousel-item">
        <img src="/assets/images/brand/nextjs.jpeg" className="carousel-image" alt="Next.js Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/graphql.jpeg" className="carousel-image" alt="GraphQL Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/reactnative.jpeg" className="carousel-image" alt="React Native Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/storybook.jpeg" className="carousel-image" alt="Storybook Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/tailwind.jpeg" className="carousel-image" alt="Tailwind CSS Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/nodejs.jpeg" className="carousel-image carousel-image-nodejs" alt="Node.js Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/aws.jpeg" className="carousel-image" alt="AWS Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/typescript.jpeg" className="carousel-image carousel-image-typescript" alt="TypeScript Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/python.jpeg" className="carousel-image" alt="Python Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/vuejs.png" className="carousel-image" alt="VueJS Logo" />
      </div>
      <div className="carousel-item">
        <img src="/assets/images/brand/googleanalytics.jpeg" className="carousel-image" alt="Google Analytics Logo" />
      </div>
    </Slider>
  );
};

export default Brand;