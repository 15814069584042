import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";
import { PiDeviceMobileSpeakerThin } from "react-icons/pi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Data Analytics & Reporting',
        description: [
            'Uncover valuable insights with our Data Analytics & Reporting services, tailored to empower your business with accurate, actionable data. Advanced Google Analytics configurations are our specialty. We handle strategy, implementation, optimization, and ongoing support. Our GA4 consulting and implementation services will take your analytics to the next level.',
            'We collaborate with IT teams to ensure seamless integration of Google Tag Manager and dataLayers across your digital platforms, including websites and mobile applications (Firebase). We prioritize tracking reliability and privacy best practices to safeguard your investment.'
        ],
        //redirect: '/service-details-da'
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: [
            'Our development process is unique to each client and each need. We take challenges seriously and like to have fun setting and reaching goals along the way. Collaboration in this process is key. We want to work with a team looking to interact with us and the strategy process, with people who are passionate about what we are doing together.',
            'We incorporate the latest web technologies to build fast, secure, and responsive websites that meet the demands of todays users.'
        ],
        //redirect: '/service-details-wd'
    },
    {
        icon: <FiUsers />,
        title: 'SEO',
        description: [
            'Search engines see web pages differently than human beings do. With direction from our SEO division, we can develop (or modify) web pages so that they are structured for both search engines and human visitors alike.'
        ],
        //redirect: '/service-details-seo'
    },
    {
        icon: <PiDeviceMobileSpeakerThin />,
        title: 'Mobile App Development',
        description: [
            'Custom mobile application development by our expert mobile app developers. From design to development and ongoing support. Custom built from scratch or code-takeover projects.',
            'Build your website a platform that works seamlessly across any mobile operating system. Including mobile apps, responsive designs, UI design tools, SDKs and support for cross platform execution.'
        ],
        //redirect: '/service-details-MPD'
    },
    {
        icon: <FiMonitor />,
        title: 'E-Commerce',
        description: [
            'Is it time to revamp your e-commerce site? Are you looking to get into the e-commerce world for the first time? Taatgen Development offers a full suite of web development services that take you from concept to implementation and beyond.',
            'Build the smartest online store with Taatgen Development ecommerce web development service in Dallas. From infrastructure addition to customer service management, your ecommerce demands all.'
        ],
        //redirect: '/service-details-ec'
    }
];

class ServiceTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverIndex: -1
        };
    }

    handleHover = (index) => {
        this.setState({ hoverIndex: index });
    };

    handleMouseLeave = () => {
        this.setState({ hoverIndex: -1 });
    };

    render() {
        const title = 'Services';

        return (
            <div className="row">
                <div className="col-lg-4 col-12">
                    <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                        <h2 className="title">{title}</h2>
                        <p>
                            Taatgen Development’s service to design and develop corporate websites involves an initial consultation to understand the client’s business goals and website requirements, website design that incorporates the client’s brand elements and focuses on user experience, website development using WordPress and incorporation of necessary features, and content creation. The end result is a custom website that reflects the client’s brand and meets their specific corporate needs.
                        </p>
                        <div className="service-btn">
                            <a className="btn-transparent rn-btn-dark" href="/contact" >
                                <span className="text">Request Custom Service</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-12 mt_md--50">
                    <div className="row service-one-wrapper">
                        {ServiceList.map((service, index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                <a
                                    onMouseEnter={() => this.handleHover(index)}
                                    onMouseLeave={this.handleMouseLeave}
                                    href={service.redirect}
                                >
                                    <div className="service service__style--2">
                                        <div className="icon">{service.icon}</div>
                                        <div className="content">
                                            <h3 className="title">{service.title}</h3>
                                            {service.description.map((paragraph, i) => (
                                                <p key={i}>
                                                    {this.state.hoverIndex === index
                                                        ? paragraph
                                                        : paragraph.length > 150
                                                            ? `${paragraph.substring(0, 150)}...`
                                                            : paragraph}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceTwo;

// const ServiceList = [
//     {
//         icon: <FiCast />,
//         title: 'Data Analytics & Reporting',
//         description: <ul>
//             <li>Uncover valuable insights with our Data Analytics & Reporting services, tailored to empower your business with accurate, actionable data.</li>
//             <li>Advanced Google Analytics configurations are our specialty. We handle strategy, implementation, optimization, and ongoing support. Our GA4 consulting and implementation services will take your analytics to the next level.</li>
//             <li>We collaborate with IT teams to ensure seamless integration of Google Tag Manager and dataLayers across your digital platforms, including websites and mobile applications (Firebase). We prioritize tracking reliability and privacy best practices to safeguard your investment.</li>
//             </ul>,
//         redirect:'/service-details-da'
//     },
//     {
//         icon: <FiLayers />,
//         title: 'Website Development',
//         description: <ul>
//             <li>Our development process is unique to each client and each need. We take challenges seriously and like to have fun setting and reaching goals along the way. Collaboration in this process is key. We want to work with a team looking to interact with us and the strategy process, with people who are passionate about what we are doing together.</li>
//             <li>We incorporate the latest web technologies to build fast, secure, and responsive websites that meet the demands of today's users.</li>
//             </ul>,
//         redirect:'/service-details-wd'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'SEO',
//         description: <ul>
//             <li>Search engines see web pages differently than human beings do.  With direction from our SEO division, we can develop (or modify) web pages so that they are structured for both search engines and human visitors alike.</li>
//             </ul>,
//         redirect:'/service-details-seo'

//     },
//     {
//         icon: <FiMonitor />,
//         title: 'Mobile App Development',
//         description: <ul>
//             <li>Custom mobile application development by our expert mobile app developers. From design to development and ongoing support. Custom built from scratch or code-takeover projects.</li>
//             <li>Build your website a platform that works seamlessly across any mobile operating system. Including mobile apps, responsive designs, UI design tools, SDKs and support for cross platform execution.</li>
//             </ul>,
//         redirect:'/service-details-MPD'

//     },
//     {
//         icon: <FiMonitor />,
//         title: 'E-Commerce',
//         description: <ul>
//             <li>Is it time to revamp your e-commerce site? Are you looking to get into the e-commerce world for the first time? Taatgen Development offers a full suite of web development services that take you from concept to implementation and beyond.</li>
//             <li>Build the smartest online store with Taatgen Development ecommerce web development service in Dallas. From infrastructure addition to customer service management, your ecommerce demands all.</li>
//             </ul>,
//         redirect:'/service-details-ec'

//     }
// ]
