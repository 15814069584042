import React from 'react';
import './Client.scss'; // Import your CSS file for styling
import './Client.css'; // Import your CSS file for styling

const App = () => {
  return (
    <div className='outer-div'>
      <div className="col-lg-12  pb--60">
        <div className="section-title text-center">
          <h3 className="centered-heading">Our Clients</h3>
        </div>
      </div>
            {/* Move left to right direction */}
      <section className="logoMarqueeSection">
        <div className="container" id="logoMarqueeSection">
          <div className="default-content-container flex items-center">
            <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
              <div className="marquee animation-container">
                <a target="_blank"><img src="/assets/images/portfolio/icon/GRAYMALIN PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/WELLS FARGO PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/CAPITAL ONE PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/SOUTHWEST PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/WHATBOARD PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/YALE HEALTH PNG EDITED.png"  title="" className="image" /></a>
              </div>
              <div className="marquee animation-container">
                <a target="_blank"><img src="/assets/images/portfolio/icon/GRAYMALIN PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/WELLS FARGO PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/CAPITAL ONE PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/SOUTHWEST PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/WHATBOARD PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/YALE HEALTH PNG EDITED.png" title="" className="image" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Move right to left direction */}
      <section className="logoMarqueeSection">
        <div className="container" id="logoMarqueeSection">
          <div className="default-content-container flex items-center">
            <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
              <div className="marquee2 animation-container2">
                <a target="_blank"><img src="/assets/images/portfolio/icon/AG1 PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/6 WAVES PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/AMERICAN WHIRLPOOL PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/SIREN PNG EDITED.png"  title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/LA LIGNE PNG EDITED.png"  title="" className="image" /></a>
              </div>
              <div className="marquee2 animation-container2">
                <a target="_blank"><img src="/assets/images/portfolio/icon/AG1 PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/6 WAVES PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/AMERICAN WHIRLPOOL PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/SIREN PNG EDITED.png" title="" className="image" /></a>
                <a target="_blank"><img src="/assets/images/portfolio/icon/LA LIGNE PNG EDITED.png" title="" className="image" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default App;