import React, { Component } from "react";

class About extends Component {
  render() {
    let title = "About Taatgen Development",
      description =
        "We are a Top-Rated Plus agency with exceptional customer reviews.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100 about-image"
                    src="/assets/images/about/about-3.jpeg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="about-taatgen" >{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">
                          Taatgen Development Solves YOUR Digital Problems!
                        </h3>
                        <p>
                          We offer reliable, consistent, and diverse software
                          solutions developed and tested by expert engineers,
                          leveraging the latest market technologies and
                          frameworks for web and mobile applications, including
                          iOS.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">
                          We Are Available Round The Clock!
                        </h3>
                        <p>
                          We prioritize understanding client needs through
                          thorough communication and offer prompt,
                          timezone-convenient support from our global team to
                          ensure top-quality services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
